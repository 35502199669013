<template>
  <div
    v-loading.fullscreen.lock="loading"
    :element-loading-text="loadingText"
    element-loading-spinner="el-icon-loading"
    element-loading-background="rgba(0, 0, 0, 0.8)"
  >
    <div v-if="showError" class="error-container mt-3">
      {{ showErrorMessage }}
    </div>
  </div>
</template>
<script>
import { postAPICall } from "@/helpers/httpHelper";
import { mapGetters } from "vuex";
import app from '../../../config/app';

export default {
  data() {
    return {
      loading: false,
      loadingText: "Verifying, please wait...",
      showError: false,
      showErrorMessage: "Authentication failure. Please try again.",
    };
  },
  mounted() {
    this.checkUrl();
  },
  computed: {
    ...mapGetters("auth", ["getThirdaPartyFormData"]),
  },
  methods: {
    async checkUrl() {
      if (
        this.$route.query?.code &&
        this.$route.query?.state &&
        this.getThirdaPartyFormData[this.$route.query?.state]
      ) {
        this.loading = true;
        try {
          const response = await postAPICall(
            "POST",
            "integrations/service/post-code",
            {
              appName:
                this.getThirdaPartyFormData[this.$route.query?.state]
                  .application,
              code: this.$route.query.code,
              redirectUrl:
                `${app.APP_URL}/configuration/APiIntegrations`,
            }
          );
          if (response?.data) {
            this.$store.commit(
              "auth/setThirdaPartyFormData",
              {
                ...this.getThirdaPartyFormData,
                ...{
                  [this.$route.query?.state]: {
                    ...this.getThirdaPartyFormData[this.$route.query.state],
                    ...{
                      connection_id: response.data
                    }
                  },
                },
              },
              {
                root: true,
              }
            );
            this.gotoConfigPage();
          }
          this.loading = false;
        } catch (e) {
          this.loading = false;
          this.showError = true;
          this.showErrorMessage = "Error while verifying. Please try again.";
        }
      } else {
        this.showError = true;
      }
    },
   
    gotoConfigPage() {
      this.$router.push({
        name: "APIIntegrations",
        query: {
          state: this.$route.query?.state,
          fromOauth: true,
        },
      });
    },
  },
};
</script>
